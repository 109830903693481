/** @jsx jsx */
import {
  extractPartnerIdsForCharacteristics,
  getCharacteristicsForId,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ExhibitorsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors, site } = afterQuery(data);
  // This is how we should do this in the future - take the settings from event
  // But for the moment, we are loading them from environment variables using useSiteConfig
  // const { filterSeekingDistribution, filterHasDistribution } = event || {};

  return (
    <ExhibitorsPage
      {...pageContext}
      event={event}
      exhibitors={exhibitors}
      siteTitle={site?.siteMetadata?.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  fragment predhomme_ExhibitorsTemplate_Bottlebooks_Event on Bottlebooks_Event {
    collectionId: eventId
    exhibitors {
      nodes {
        exhibitorId
        ...bb_ExhibitorsPage
      }
    }
    registrations {
      nodes {
        ...exhibitorFilters
        ...predhomme_exhibitorFilters
      }
    }
    registeredBrands {
      nodes {
        partnerId
        ...bb_ExhibitorsPage_RegisteredBrand
        ...exhibitorFilters_RegisteredBrand
      }
    }
    # This would bring down the api server, so it's disabled by default.
    # registeredProducts {
    #   ...bb_BrandFilters_RegisteredProductConnection
    # }
  }

  query predhomme_ExhibitorsTemplate(
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    # 62ecd313f394f910d1d6d29f,62ecd2fe123f1410cadbbba4,62ecd2b3f28b1e10cc33eb5d,62ecd29062842e10d25d8961
    bottlebooks {
      oregon: event(eventId: "62ed7af462842e10d25d8a35", locale: $locale) {
        ...predhomme_ExhibitorsTemplate_Bottlebooks_Event
      }
      washington: event(eventId: "6352fab2dc59de50f0e83c6b", locale: $locale) {
        ...predhomme_ExhibitorsTemplate_Bottlebooks_Event
      }
    }
    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
        ...exhibitorFilter_Exhibitor
        ...exhibitorMultiEventFilter
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment ExhibitorsTemplate_Event on Event {
    # This is how we should do this in the future - take the settings from event
    # But for the moment, we are loading them from environment variables using useSiteConfig
    # filterSeekingDistribution
    # filterHasDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstExhibitors, exhibitors, site }) {
  // Combine exhibitors from all events, indicating which event they are in
  const events = Object.values(bottlebooks);

  const bb_exhibitors = mergeBbExhibitors(events);

  // Dedupe exhibitors from Gatsby GraphQL
  const dedupedExhibitors = dedupeExhibitors(exhibitors.nodes);

  // To-do: apply dedupe logic to registeredBrands and registrations
  const bb_event = events[0];

  const bb_registeredBrands = dedupeRegisteredBrands([
    ...events[0]?.registeredBrands?.nodes,
    ...events[1]?.registeredBrands?.nodes,
  ]);
  const bb_registrations = [
    ...events[0]?.registrations?.nodes,
    ...events[1]?.registrations?.nodes,
  ];
  // Enrich the first exhibitors with better images and base64 previews.
  const combinedExhibitors = dedupedExhibitors?.map((exhibitor, index) => {
    // Because we source information from multiple events, it doesn't match the
    // exhibitors from Gatsby GraphQL
    // const refinedExhibitor = firstExhibitors?.nodes[index];
    const bb_exhibitor = bb_exhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    const bb_registeredBrand = bb_registeredBrands?.find(
      ({ partnerId }) => partnerId === exhibitor.exhibitorId
    );

    // The deepMerge logic below doesn't pay attention to this. RegisteredBrand.profile
    // is not the exhibitor profile.
    const safe_bb_registeredBrand = {
      ...bb_registeredBrand,
      profile: null,
    };

    // Get all the brands that this exhibitor has registered
    const brandsRepresented = bb_registeredBrands?.filter(
      ({ partnerId }) => partnerId === exhibitor.exhibitorId
    );

    const isSeekingDistribution =
      brandsRepresented?.some((brand) => brand.isSeekingDistribution) ||
      exhibitor.isSeekingDistribution;

    // Extract has and seeking distribution
    const distributionInCountries = brandsRepresented.reduce((acc, brand) => {
      acc.push(...(brand.profile.distributionInCountries || []));
      return acc;
    }, []);

    const seekingDistributionInCountries = brandsRepresented.reduce(
      (acc, brand) => {
        acc.push(...(brand.profile.seekingDistributionInCountries || []));
        return acc;
      },
      []
    );

    const bb_registration = bb_registrations?.find(
      ({ companyId }) => companyId === exhibitor.exhibitorId
    );

    return deepMerge(
      {},
      exhibitor,
      bb_exhibitor,
      safe_bb_registeredBrand,
      // We inject all the distribution settings of the brands into
      // the profile of the exhibitor.
      {
        isSeekingDistribution,
        profile: {
          distributionInCountries,
          seekingDistributionInCountries,
        },
      },
      bb_registration,
      // refinedExhibitor,
      {
        characteristics: getCharacteristicsForId({
          extractIdsFn: extractPartnerIdsForCharacteristics,
          id: exhibitor.exhibitorId,
          event: bb_event,
        }),
      }
    );
  });

  return {
    event,
    site,
    exhibitors: combinedExhibitors,
  };
}

function mergeBbExhibitors(events) {
  const exhibitors = events.reduce((acc, event) => {
    const { exhibitors } = event;
    const exhibitorNodes = exhibitors?.nodes || [];

    exhibitorNodes.map((exhibitor) => {
      const { exhibitorId } = exhibitor;
      if (acc[exhibitorId]) {
        acc[exhibitorId].collectionIds.push(event.collectionId);
        acc[exhibitorId].standNames.push({
          collectionId: event.collectionId,
          standName: exhibitor.stand?.name,
        });
      } else
        acc[exhibitorId] = {
          ...exhibitor,
          collectionIds: [event.collectionId],
          standNames: [
            {
              collectionId: event.collectionId,
              standName: exhibitor.stand?.name,
            },
          ],
        };
    });
    return acc;
  }, {});

  return Object.values(exhibitors);
}

// Remove duplicate exhibitors
function dedupeExhibitors(exhibitors) {
  const uniqueExhibitors = {};
  exhibitors.forEach((exhibitor) => {
    uniqueExhibitors[exhibitor.exhibitorId] = exhibitor;
  });
  return Object.values(uniqueExhibitors);
}

// Remove duplicate registered brands
function dedupeRegisteredBrands(dedupeRegisteredBrands) {
  const uniqueRegisteredBrands = {};
  dedupeRegisteredBrands.forEach((brand) => {
    uniqueRegisteredBrands[brand.brandId] = brand;
  });
  return Object.values(uniqueRegisteredBrands);
}
